import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetPollsVoteFetch } from '../Api/Fetchs/getPolls';

interface IPostState {
	data: Array<any>;
	Loading: boolean;
	Error: boolean;
}

export function* getPollsVoteAllsaga(action: ReturnType<typeof getVote>): any {
	try {
		yield put(getPollsVoteLoading());
		const params = action.payload || {};
		const response = yield call(GetPollsVoteFetch, params);
		const payload = yield call([response, 'json']);
		yield put(getPollsVoteSuccess(payload));
	} catch (error) {
		yield put(getPollsVoteError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false,
};

const getPollsVote = createSlice({
	name: 'getPollsVote',
	initialState,
	reducers: {
		getPollsVoteLoading: (state) => {
			state.Loading = true;
		},
		getPollsVoteSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getPollsVoteError: (state, action) => {
			state.Error = action.payload;
		},
	},
});

export const GET_VOTE = 'topic/getPollsVote';

export const getVote = createAction<{
	PollId?: any;
	options?: any;
}>(GET_VOTE);

export const { getPollsVoteLoading, getPollsVoteSuccess, getPollsVoteError } =
	getPollsVote.actions;
export default getPollsVote.reducer;
