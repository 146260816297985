import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetPollsFetch } from '../Api/Fetchs/getPolls';

interface IPostState {
	data: Array<any>;
	Loading: boolean;
	Error: boolean;
	combinedData: Array<any>;
}

const removeDuplicates = (arr: any) => {
	const uniqueArray = arr.filter(
		(value: any, index: any, self: any) =>
			index === self.findIndex((t: any) => t.id === value.id)
	);
	return uniqueArray;
};

export function* getPollsAllsaga(action: ReturnType<typeof getPolls>): any {
	try {
		yield put(getPollsLoading());
		const params = action.payload || {};
		const response = yield call(GetPollsFetch, params);
		const payload = yield call([response, 'json']);
		yield put(getPollsSuccess(payload));
	} catch (error) {
		yield put(getPollsError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false,
	combinedData: [],
};

const getPollsAll = createSlice({
	name: 'getPollsAll',
	initialState,
	reducers: {
		getPollsLoading: (state) => {
			state.Loading = true;
		},
		getPollsSuccess: (state, action) => {
			state.Loading = false;
			state.combinedData.push(action.payload);
			state.data = removeDuplicates(state.combinedData).map((el: any) => {
				return el;
			});
		},
		getPollsError: (state, action) => {
			state.Error = action.payload;
		},
	},
});

export const GET_POLLS = 'topic/getPolls';

export const getPolls = createAction<{
	PollId?: any;
}>(GET_POLLS);

export const { getPollsError, getPollsLoading, getPollsSuccess } =
	getPollsAll.actions;
export default getPollsAll.reducer;
