'use client';
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from '@/Redux/Slices/PollsSlice/states';

//--------------------------------------------------------------
export const PollsState = createSlice({
	name: 'PollsState',
	initialState,
	reducers: {
		SetAnswer(state, action) {
			if (!state.isOn.includes(action.payload.active)) {
				state.isOn.push(action.payload.active);
			}
			if (
				!state.Answer.includes({
					ans: action.payload.answer,
					numQuiz: action.payload.active,
				})
			) {
				state.Answer.push({
					ans: action.payload.answer,
					numQuiz: action.payload.active,
				});
			}
		},
	},
});

export default PollsState.reducer;
