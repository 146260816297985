import { useEffect, useState, Children, cloneElement } from 'react';
import style from './Slider.module.sass';

export const SliderMain = ({ children, pages, setPages }: any) => {
	// ШИРИНА ОКНА

	const useWindowWidth = () => {
		const [width, setWidth] = useState(
			typeof window !== 'undefined' ? window.innerWidth : 0
		);

		useEffect(() => {
			if (typeof window !== 'undefined') {
				const handleResize = () => setWidth(window.innerWidth);
				window.addEventListener('resize', handleResize);
				return () => window.removeEventListener('resize', handleResize);
			}
		}, []);

		return width;
	};

	const width = useWindowWidth();

	// =========================================

	const [offset, setOffset] = useState(0);

	useEffect(() => {
		setPages(
			Children.map(children, child => {
				return cloneElement(child, {
					style: {
						height: '100%',
						minWidth: '100%',
						maxWidth: '100%'
					}
				});
			})
		);
	}, []);

	const handleLeftArrowClick = () => {
		setOffset((currentOffset): any => {
			const newOffset = currentOffset + (width < 575 ? width : 400);

			return Math.min(newOffset, 0);
		});
	};
	const handleRightArrowClick = () => {
		setOffset((currentOffset): any => {
			const newOffset = currentOffset - (width < 575 ? width : 400);

			const maxOffset = -(width * (pages.length - 1));
			return Math.max(newOffset, maxOffset);
		});
	};
	const [TouchEvent, setTouchEvent] = useState(0);
	// const [Arrows, setArrows] = useState(0);
	const handleTouchStart = (e: any) => {
		setTouchEvent(e.touches[0].clientX);
	};
	const handleTouchEnd = (e: any) => {
		const endX = e.changedTouches[0].clientX;
		if (TouchEvent - endX > 50) {
			if (offset == -((width < 575 ? width : 400) * (pages.length - 1))) {
				return;
			} else {
				handleRightArrowClick();
			}
		} else if (endX - TouchEvent > 50) {
			if (offset == 0) {
				return;
			} else {
				handleLeftArrowClick();
			}
		}
	};

	return (
		<div
			className={style.wrapper}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			style={{ touchAction: 'pan-y' }}
		>
			<div
				className={style.Arrowleft}
				onClick={handleLeftArrowClick}
				style={{ display: offset == 0 ? 'none' : 'flex' }}
			>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M15 6L9 12L15 18' stroke='#33363F' stroke-width='2' />
				</svg>
			</div>
			<div className={style.container}>
				<div
					className={style.allPages}
					style={{
						transform: `translateX(${offset}px)`
					}}
				>
					{children}
				</div>
			</div>
			<div
				className={style.ArrowRight}
				onClick={handleRightArrowClick}
				style={{
					display:
						offset == -((width < 575 ? width : 400) * (pages?.length - 1))
							? 'none'
							: 'flex'
				}}
			>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M9 6L15 12L9 18' stroke='#33363F' stroke-width='2' />
				</svg>
			</div>
		</div>
	);
};
