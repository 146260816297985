export const GetPollsFetch = async ({ PollId }: any) => {
	return await fetch(`https://polls.smi2.ru/body/1/poll/${PollId}`, {
		method: 'GET',
		mode: 'cors',

		headers: new Headers({ 'content-type': 'application/json' })
	});
};

export const GetPollsVoteFetch = async ({ PollId, options }: any) => {
	return await fetch(`https://polls.smi2.ru/body/1/poll/${PollId}/vote`, {
		method: 'POST',
		mode: 'cors',
		body: `optionId=${options}`,

		headers: new Headers({
			'content-type': 'application/x-www-form-urlencoded'
		})
	});
};
