interface GetNewsParams {
	block_id: number;
	count: number;
	fields: number;
}
type CallbackFunction = (payload: any) => void;
interface JsAPIDao {
	getNews: (params: GetNewsParams, callback: CallbackFunction) => void;
}

declare global {
	var JsAPI: { Dao: JsAPIDao };
}
export const OpenTeaser = (els: any) => {
	if (window.ReactNativeWebView) {
		// url
		// const message = {
		// 	type: 'openTeaser',
		// 	url: els
		// };

		window.ReactNativeWebView.postMessage(JSON.stringify(['openTeaser', els]));
	} else {
	}
};
